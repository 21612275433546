const data = [
   
    {
        name: "Prof. Neeraj Goyal",
        ImageUrl: "/Team/proffs/IMG_20231217_120017.png",
        position: "Chairman",
        email: "mailto:ngoyal@hijli.iitkgp.ac.in",
    },
    {
        name: "Prof. N S Devra",
        ImageUrl: "/Team/proffs/IMG_20231217_120031.png",
        position: "Chairman Committee Member",
        email: "mailto:nsdevra@ece.iitkgp.ac.in",
    },
    {
        name: "Prof. Peeyush Soni",
        ImageUrl: "/Team/proffs/IMG_20231217_115954.png",
        position: "Chairman Committee Member",
        email: "mailto:soni@iitkgp.ac.in",
    },
   
  
]

export default data