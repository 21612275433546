const data = [
    {
        number : '01',
        title : "Fresher's welcome",
        image : "images/freshers.png",
        desc : " RCA welcomes new students of IIT KGP through an interactive cultural function."
    },
    {
        number : '02',
        title : "Kite fest",
        image : "images/kite.jpg",
        desc : " A opportunity to have the leisure of kite playing on Makar - Sakranti. A huge participation from the side of both students and professors."
    },
    {
        number : '03',
        title : "Khamma ghani",
        image : "images/food_fest.jpg",
        desc : "  Organized on the occasion of Holi, it is very popular among students and professors. Foodies who want to enjoy the delicious Rajasthani food wait eagerly for this event "
    },
    {
        number : '04',
        title : "Cultural Night",
        image : "images/g2.jpg",
        desc : "    A fun event full of cultural events like standup comedy, dance, singing."
    },
    {
        number : '05',
        title : "Sports fest",
        image : "images/g6.png",
        desc : " Organised for the KGP community to encourage and promote sports like Kabaddi and Kho Kho"
    },
    {
        number : '06',
        title : "Farewell",
        image : "images/farewell.png",
        desc : "  Bidding goodbye to the passing out batch and farewell trip."
    },
    {
        number : '07',
        title : "Capture it",
        image : "images/CTT4.png",
        desc : " An opportunity to show creative photography skills during kite fest, and Capturing the whole event memories."
    },
    {
        number : '08',
        title : "Education Drive",
        image : "images/Education_drive.png",
        desc : "  RCA organised this to provide the quality education to the needy students at the time of pandemic when their schools were closed, so that effects on their education can be minimalized."
    },
    {
        number : '09',
        title : "Quiz Competition",
        image : "images/Online_Quiz.jpg",
        desc : "RCA, in association with Myswots organised an online quiz which provided a wonderful opportunity for Marvel and IPL fans to win excited prizes."
    },
]

export default data;