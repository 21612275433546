const data = [
   
    {
        name: "Mayank Kulhari",
        ImageUrl: "/Team/Seniors/mayank.jpg",
        position: "Advisor",
        facebookLink: "https://www.facebook.com/profile.php?id=100074791413160",
        linkedinLink: "http://www.linkedin.com/in/mayank-kulhari-395b3122a"
    },
    {
        name: "Ajay Kumar Dhakar",
        ImageUrl: "/Team/Seniors/ajay.jpg",
        position: "Advisor",
        facebookLink: "https://www.facebook.com/ajay.dhakar.146",
        linkedinLink: "https://www.linkedin.com/in/ajay-kumar-dhakar-2b460822a"
    },
    {
        name: "Archisha Gupta",
        ImageUrl: "/Team/Seniors/Archisha_.jpg",
        position: "Advisor",
        facebookLink: "https://www.facebook.com/profile.php?id=100075216734906",
        linkedinLink: "https://www.linkedin.com/in/archisha-gupta-a6831a227/"
    },
   
  
]

export default data