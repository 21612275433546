import './about.css'
import Brad from '../Brad/Brad'
import React, { useEffect, useState } from 'react';
const About = () => {
  function Counter({ initialValue, targetValue, duration }) {
    const [currentValue, setCurrentValue] = useState(initialValue);

    useEffect(() => {
      let startTime;
      let animationFrameId;

      const animateCounter = (timestamp) => {
        if (!startTime) {
          startTime = timestamp;
        }

        const progress = (timestamp - startTime) / duration;
        const newValue = Math.floor(initialValue + (progress * (targetValue - initialValue)));

        if (newValue < targetValue) {
          setCurrentValue(newValue);
          animationFrameId = requestAnimationFrame(animateCounter);
        } else {
          setCurrentValue(targetValue);
        }
      };

      animationFrameId = requestAnimationFrame(animateCounter);

      return () => cancelAnimationFrame(animationFrameId);
    }, [initialValue, targetValue, duration]);

    return <span>{currentValue}</span>;
  }
  return (
    <>
<Brad head={"About Us"}/>
      {/* <!-- pet_care_area_start  --> */}
      <div class="pet_care_area">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-5 col-md-6">
              <div class="pet_thumb">
                <img src="img/about/pet_care.png" alt="" />
              </div>
            </div>
            <div class="col-lg-6 offset-lg-1 col-md-6">
              <div class="pet_info">
                <div class="section_title">
                  <h3><span> </span> <br />
                    WHO ARE WE</h3>
                  <p>Rajasthan has a rich ancient culture and glorious history.
                    Drenched into royal grandeur and soaked into glorious history,
                    Rajasthan is most charming in its flora and fauna. So, we here come forward to enrich the life of students and
                    provide them a wide platform where they can learn a lot of
                    skills, show case their talents and develop a self confidene and
                    potential to achieve heights of glory. So we conduct various
                    events at regular intervals for proper intermixing of students
                    with one another for their all round development.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- pet_care_area_end  --> */}

      {/* <!-- adapt_area_start  --> */}
      <div class="adapt_area">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-6">
              <div class="adapt_help">
                <div class="section_title">
                  <h3><span></span>ORIGIN</h3>
                  <p>The foundation stone of this journey was laid with the idea of brotherhood and unity among diversity. It was an attempt to encourage students to come together to learn extra curricular activities beyond their academics. RCA has grown up as an prestigeous organisation over the years, with more than 50 alumni placed at various coorporate and goverment organisations. A dedicated team working towards achieving new milestones every year.</p>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="adapt_help">
                <div class="section_title" align="center">
                  <img src="img/kgp.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="adapt_area">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-lg-5">
              <div class="adapt_help">
                <div class="section_title">
                  <h3> VISION</h3>
                  <p>Our vision is to spread the rich culture of Rajasthan in the KGP cummunity along with celebration of festivals which are a major missing for a student far from his home. We are always progressing on the path of reaching to more KGPians and involving them in various cultural activities.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="adapt_about">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-md-6">
                    <div className="single_adapt text-center">
                      <div className="adapt_content">
                        <h3>
                          <Counter initialValue={0} targetValue={12000} duration={10000} />
                        </h3>
                        <p>Students, Professors and Staff</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="single_adapt text-center">
                      <div className="adapt_content">
                        <h3>
                          <Counter initialValue={0} targetValue={20000} duration={10000} />
                        </h3>
                        <p>Facebook Reach</p>
                      </div>
                    </div>
                    <div className="single_adapt text-center">
                      <div className="adapt_content">
                        <h3><span>9+</span></h3>
                        <p>Glorious Years</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- adapt_area_end  --> */}
    </>
  )
}

export default About;