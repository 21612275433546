import { useState } from "react";
import "./MalhaarRegistrations.css";

function MalhaarRegistrations() {
  const scriptURL =
    "https://script.google.com/macros/s/AKfycbxwni8Y4Jk9DR1kENtufPhDv86NBPtgKGXuPl7247nZPNf57RG4oHrp-1yV3FjQxoh7xw/exec";
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setButtonText("Please Wait...");
    fetch(scriptURL, {
      method: "POST",
      body: new FormData(e.target),
    })
      .then((response) => {
        alert("Thank you! Your form is submitted successfully.");
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error!", error.message);
        setIsSubmitting(false);
        setButtonText("Register"); // Revert button text if submission fails
      });
    // .catch((error) => console.error('Error!', error.message));
  };
  const [Name, setname] = useState("");
  const [rollNo, setrollno] = useState("");
  const [email, setemail] = useState("");
  const [hall, sethall] = useState("");
  const [phone, setphone] = useState("");
  const [WhatPerformance, setperformance] = useState("");
  const [member, setmember] = useState("");
  const [buttonText, setButtonText] = useState("Register");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlename = (event) => {
    setname(event.target.value);
  };
  const handleroll = (event) => {
    setrollno(event.target.value);
  };
  const handlemail = (event) => {
    setemail(event.target.value);
  };
  const handlehall = (event) => {
    sethall(event.target.value);
  };
  const handlephone = (event) => {
    setphone(event.target.value);
  };
  const handlemember = (event) => {
    setmember(event.target.value);
  };
  const handleperformance = (event) => {
    setperformance(event.target.value);
  };

  return (
    <>
      <div class="BODY">
        {/* <!-- Modal --> */}
        <div class="back-color">
          <div class="full-screen">
            <div class="card">
              <div class="main-form">
                <div class="left-part">
                  <div class="">
                    <form
                      method="post"
                      onSubmit={handleSubmit}
                      action="https://script.google.com/macros/s/AKfycbxwni8Y4Jk9DR1kENtufPhDv86NBPtgKGXuPl7247nZPNf57RG4oHrp-1yV3FjQxoh7xw/exec"
                      name="contactForm"
                    >
                      <h3 class="main-heading">MALHAAR PERFORMERS</h3>
                      <div class="heading_data">
                        <p>Event Date:- 26 OCT 2024</p>
                        <p>Event Timing:- 7 PM TO 10 PM</p>
                        <p>Event Venue:- Technology Club</p>
                      </div>
                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Name *
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Name"
                            id="name"
                            placeholder="Your name"
                            value={Name}
                            onChange={handlename}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Roll Number --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Roll Number *
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Roll No."
                            placeholder="Your Roll.No."
                            value={rollNo}
                            onChange={handleroll}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Email --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Email *
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Email"
                            id="email"
                            placeholder="Your email"
                            value={email}
                            onChange={handlemail}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Hall --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Hall *
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Hall"
                            id="phone"
                            placeholder="Hall"
                            value={hall}
                            onChange={handlehall}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Phone --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Phone *
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Contact Number"
                            id="phone"
                            placeholder="Phone No."
                            value={phone}
                            onChange={handlephone}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- What are you going to perform --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            What You are going to perform ?
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="What You are going to perform"
                            placeholder="What You are going to perform"
                            value={WhatPerformance}
                            onChange={handleperformance}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- No. of members ? --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Number of team members
                          </label>
                          <input
                            type="text"
                            class="ans"
                            name="Number of team members"
                            placeholder="Number of team members"
                            value={member}
                            onChange={handlemember}
                            required
                          />
                        </div>
                      </div>

                      {/* <!-- Time Slots --> */}

                      <div class="line">
                        <div class="details">
                          <label for="" class="our-q">
                            Prefarable Timing
                          </label>
                          <select
                            type="text"
                            class="ans"
                            name="Time Slots"
                            placeholder="Prefarable Timing"
                            required
                          >
                            <option value="">Select</option>
                            <option value="7:00PM - 7:30PM">
                              7:00PM - 7:30PM
                            </option>
                            <option value="7:30PM - 8:00PM">
                              7:30PM - 8:00PM
                            </option>
                            <option value="8:00PM - 8:30PM">
                              8:00PM - 8:30PM
                            </option>
                            <option value="8:30PM - 9:00PM">
                              8:30PM - 9:00PM
                            </option>
                            <option value="9:00PM - 9:30PM">
                              9:00PM - 9:30PM
                            </option>
                            <option value="9:30PM - 10:00PM">
                              9:30PM - 10:00PM
                            </option>
                          </select>
                        </div>
                      </div>

                      {/* <!-- finish--> */}

                      <div class="btn-box">
                        <input
                          type="submit"
                          disabled={isSubmitting}
                          value={buttonText}
                          id="submit"
                          class="submit-btn"
                        />
                      </div>

                      {/* </form> */}
                    </form>
                  </div>
                </div>

                <div class="right-part">
                  <div class="contact-inf">
                    <img
                      src="/img/FreshersRegistration/rcaglow.png"
                      class="rca-logo"
                      alt="..."
                    />

                    <br />
                    <br />
                    <br />
                    <h3 class="contact">Contact Information</h3>
                    <p class="rca-name">
                      Rajasthan Cultural Association IIT Kharagpur
                    </p>
                    <ul class="ul11">
                      <li class="dot">
                        <span class=""></span>
                        <span class="">IIT Kharagpur West Bengal 721302</span>
                      </li>
                      <li class="dot">
                        <span class=""></span>
                        <span class="">+91-8302434347</span>
                      </li>
                      <li class="dot">
                        <span class=""></span>
                        <span class="text">rca.iitkgp@gmail.com</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MalhaarRegistrations;
