const data = [
   
    {
        name: "Ankit Dhaka",
        ImageUrl: "/Team/Seniors/ankitdhaka.jpg",
        position: "Governor",
        facebookLink: "https://www.facebook.com/profile.php?id=100073476429414",
        linkedinLink: "https://www.linkedin.com/in/ankit-dhaka-62426422b"
    }
   
  
]

export default data;