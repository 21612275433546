const data = [
    {
        name: "Praveen Kumar Yadav",
        ImageUrl: "/Team/Heads/Praveen kumar yadav.jpg",
        position: "Sponsorship Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100004452134754&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/praveenkumaryadav63?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Pradnya Ukey",
        ImageUrl: "/Team/Heads/Pradnya Ukey.jpg",
        position: "Design Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100050781124077&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/pradnya-ukey-899629256?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Aditya Singh Sisodia",
        ImageUrl: "/Team/Heads/Aditya Singh Sisodia.jpg",
        position: "Design Head",
        facebookLink: "https://www.facebook.com/legendaryterminator?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/aditya-singh-sisodia-73858b257?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Ashish Kumar Meena",
        ImageUrl: "/Team/Heads/Ashish kumar Meena.jpg",
        position: "Web Design Head",
        facebookLink: "https://www.facebook.com/ashishmeena.dewanda?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/ashish-kumar-meena-8bb227255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Sambhav Jain",
        ImageUrl: "/Team/Heads/sambhav.jpg",
        position: "Finance Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100088695458252",
        linkedinLink: "https://www.linkedin.com/in/sambhav-jain-648034221/"
    },
    {
        name: "Gajanand Gena",
        ImageUrl: "/Team/Heads/gaju.jpeg",
        position: "Alumni Coordinator | Sponsorship head",
        facebookLink: "https://www.facebook.com/gajanand.gena.25",
        linkedinLink: "https://www.linkedin.com/in/gajanand-gena/"
    },
    {
        name: "Pragya Bhootra",
        ImageUrl: "/Team/Heads/Pragya Bhootra.png",
        position: "Sponsorship Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100092295873299",
        linkedinLink: "https://www.linkedin.com/in/pragya-bhootra-333bb2258/"
    },  
    {
        name: "Palak Agarwal",
        ImageUrl: "/Team/Heads/Palak Agrawal.jpeg",
        position: "Sponsorship Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100086204021248&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/palak-agrawal-0a546724a"
    },
    {
        name: "Bhawani Shankar Ola",
        ImageUrl: "/Team/Heads/Bhawani singh.jpg",
        position: "Public Relation Head",
        facebookLink: "https://www.facebook.com/bhawani.ola.9?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/bhawani-shankar-4a264127a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Megha Singhal",
        ImageUrl: "/Team/Heads/megha.jpg",
        position: "Public Relation Head",
        facebookLink: "https://www.facebook.com/megha.singhal.35325",
        linkedinLink: "https://www.linkedin.com/in/megha-singhal-7a9a3926a/"
    },
    {
        name: "Mansi Meena",
        ImageUrl: "/Team/Heads/Mansi Meena.jpg",
        position: "Event Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100087874150973&mibextid=zLoPMf",
        linkedinLink: "https://www.linkedin.com/in/mansi-meena-99720828a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Abhishek Choudhary",
        ImageUrl: "/Team/Heads/Abhishek.jpeg",
        position: "Event Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100039444165682&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/abhishek-choudhary-135359256"
    },
    {
        name: "Nancy Bishnoi",
        ImageUrl: "/Team/Heads/nancy.jpg",
        position: "Event Head",
        facebookLink: "https://www.facebook.com/profile.php?id=100090508256061",
        linkedinLink: "https://www.linkedin.com/in/nancy-bishnoi-4102b5290"
    },
   

]

export default data