import React, {useEffect} from 'react'
import './Card.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiSolidPhone } from 'react-icons/bi';
function Card(props) {
    useEffect(() => {
        AOS.init();
    }, []);
    return (
        <>
            <div class="testmonial_area">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-10">
                            <div class="testmonial_wrap">
                                <div class="single_testmonial d-flex align-items-center" data-aos="fade-right"
                                    data-aos-duration="500">
                                    <div class="test_thumb">
                                        <img src={props.image} alt="" />
                                    </div>
                                    <div class="test_content align-items-center" >
                                        <h4>{props.name}</h4>
                                        <span>{props.type}</span>
                                        <h5 class="font-weight-normal">{props.desc}</h5>
                                        <p>{props.address}</p>
                                        <br />
                                        <i class="fa fa-phone"><BiSolidPhone/><a href="tel:+9800004570" target="blank">{props.phone}</a></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </>
    )
}

export default Card