const data = [ 
    {
        name: "Nishant meena",
        ImageUrl: "/Team/SubHeads/Nishant Meena.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61551089254943&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/nishant-meena-056154289?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },  
    {
        name: "Sunny",
        ImageUrl: "/Team/SubHeads/sunny.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/sunny.kataria.58511?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/sunny-065a19288"
    },
    {
        name: "Vikash Rathore ",
        ImageUrl: "/Team/SubHeads/Vikash Rathore.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/vikash.rathore.7399786?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/vikash-rathore-712596289"
    },
    {
        name: "Rohit palliwal ",
        ImageUrl: "/Team/SubHeads/Rohit Palliwal.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/rohit.palliwal.5",
        linkedinLink: "www.linkedin.com/in/rohit-palliwal-0567a22b3"
    }, 
    {
        name: "Deepraj Choudhary ",
        ImageUrl: "/Team/SubHeads/Deepraj Choudhary.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/deepraj.jat.58?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/deepraj-choudhary-aba008297?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Aastha Jhunjhunwala ",
        ImageUrl: "/Team/SubHeads/Aastha Jhunjhunwala.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61552215805530&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/aastha-jhunjhunwala-53b5b7293"
    },
    {
        name: "Sujal Chaurasia",
        ImageUrl: "/Team/SubHeads/Sujal chaurasia.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/sujalkumar.chaurasia?paipv=0&eav=AfZLRVE8YqGZM2Zn3FoyU6W2sSEZju_qtjyYEkzhYL_Zf3dfJOCnny7HDJOldJ-GlCY&_rdr",
        linkedinLink: "https://www.linkedin.com/in/sujal-chaurasia-iitkgp28/"
    },
    {
        name: "Praveen Jangir",
        ImageUrl: "/Team/SubHeads/praveen jangir.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550421650292&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/praveen-jangir-501819290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Rohit Bijarniya ",
        ImageUrl: "/Team/SubHeads/rohit bijarniya.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550126452277&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/rohit-bijarniya-6a84b5282?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Divya Jain",
        ImageUrl: "/Team/SubHeads/Divya Jain.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100094262888730&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/divya-jain-2a6315289/"
    },
    {
        name: "Anisha Choudhary",
        ImageUrl: "/Team/SubHeads/Anisha Choudhary.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550266934078&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/anisha-choudhary-9b3989280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Nikhil Mourya ",
        ImageUrl: "/Team/SubHeads/Nikhil Mourya.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550116311408&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/nikhil-mourya-1a6147291?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Hitesh Godara",
        ImageUrl: "/Team/SubHeads/Hitesh Godara.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/share/izuxTkTpDNNYYFwm/?mibextid=qi2Omg",
        linkedinLink: "https://www.linkedin.com/in/hitesh-godara-628815322?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Vikash Siyak ",
        ImageUrl: "/Team/SubHeads/Vikash Siyak.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550563805245&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/vikash-siyak-221697293"
    },
    {
        name: "Kajal Choudhary",
        ImageUrl: "/Team/SubHeads/Kajal Choudhary.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61559785183287",
        linkedinLink: "https://www.linkedin.com/in/kajal-choudhary-2b5826298"
    },
    {
        name: "Rishabh Prajapat",
        ImageUrl: "/Team/SubHeads/Rishabh Prajapat.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100095581773021&mibextid=kFxxJD",
        linkedinLink: "https://www.linkedin.com/in/rishabh-prajapat-221849280?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Harshvardhan Repaswal",
        ImageUrl: "/Team/SubHeads/Harshvardhan Repaswal.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100095222182589&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/harshvardhan-repaswal-1777a427b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Sanket Rewar",
        ImageUrl: "/Team/SubHeads/Sanket Rewar.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/share/MBgbh3B3xKnAP1Ry/?mibextid=qi2Omg",
        linkedinLink: "https://www.linkedin.com/in/sanket-rewar-654ab3284?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
   
    {
        name: "Gaurav Sirohi",
        ImageUrl: "/Team/SubHeads/Gaurav Sirohi.jpeg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/gauravchoudhary.44?mibextid=LQQJ4d",
        linkedinLink: "https://www.linkedin.com/in/gaurav-sirohi-24600027a"
    },
   
    {
        name: "Pulkit Kulhari ",
        ImageUrl: "/Team/SubHeads/Pulkit Kulhari.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100094548256808&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/pulkit-kulhari-484a8328b/"
    },

  
    {
        name: "Dakshita Pokharna ",
        ImageUrl: "/Team/SubHeads/Dakshita Pokharna.jpeg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/dakshita.pokharna.7?mibextid=ZbWKwL",
        linkedinLink: "www.linkedin.com/in/dakshita-pokharna-555bb3284"
    },
    {
        name: "Pramod Kumar ",
        ImageUrl: "/Team/SubHeads/Pramod Kumar.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100047459503375&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/pramod-kumar-252501290?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Kanchan Godara ",
        ImageUrl: "/Team/SubHeads/Kanchan Godara.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=61550199590264&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/kanchan-godara-65baa129a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
    },
    {
        name: "Rohan ",
        ImageUrl: "/Team/SubHeads/Rohan.jpg",
        position: "SubHead",
        facebookLink: "https://www.facebook.com/profile.php?id=100095492954342",
        linkedinLink: "https://www.linkedin.com/in/rohan-datusalia-553ba02a2/"
    },


    
    
]

export default data