import React from 'react'
// import img1 from "./images/CTT_1.png";
import img2 from "./images/CTT3.png";
import img3 from "./images/CTT4.png";
import img4 from "./images/CTT9.png";
import img5 from "./images/CTT10.png";
import img6 from "./images/Education_drive.png";
import img7 from "./images/Freshers_Welcome_22_2.png";
import img8 from "./images/Freshers_Welcome_22_1.png";
import img9 from "./images/Freshers_Welcome_22_3.png";
import img10 from "./images/Freshers_Welcome_22_4.png";
import img11 from "./images/Freshers_Welcome_22_5.png";
import img12 from "./images/Freshers_Welcome_22_6.jpeg";
import img13 from "./images/g1.png";
import img14 from "./images/g2.jpg";
import img15 from "./images/g3.png";
import img16 from "./images/g5new.png";
import img17 from "./images/g6.png";
import img18 from "./images/g7.png";
import img19 from "./images/g8.jpg";
import img21 from "./images/Online_EducationDrive.png";
import img22 from "./images/Sohail_ED.png";

import "./Gallery.css";
function Gallery() {
    return (
        <>
            <div className="imo" style={{ padding: "2rem" }}>
                <h2 className='image-name' style={{ fontSize: "2rem !important "}}>    <span style={{ color: "Orange" }}>I</span>mage Gallery</h2>
            </div>
            <div className="imo">
                <h1>

                </h1>
            </div>
            <div className="main-contain">
                <div className="gallery-images">
                    <img src={img14} style={{objectFit:"cover"}}  alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img2}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img3}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img4}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img5}  style={{objectFit:"cover"}} alt=" 0" />
                </div>

                <div className="gallery-images">
                    <img src={img6}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img7}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img8}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img9}  style={{objectFit:"cover"}} alt=" " />
                </div>

                <div className="gallery-images">
                    <img src={img10} style={{objectFit:"cover"}}  alt=" - " />
                </div>

                <div className="gallery-images">
                    <img src={img11} style={{objectFit:"cover"}}  alt="   - " />
                </div>

                <div className="gallery-images">
                    <img src={img12} style={{objectFit:"cover"}}  alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img13} style={{objectFit:"cover"}}  alt=" " />
                </div>
                {/* <div className="gallery-images">
                    <img src={img1}  style={objet}alt=" " />
                </div> */}
                <div className="gallery-images">
                    <img src={img15} style={{objectFit:"cover"}}  alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img16} style={{objectFit:"cover"}}  alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img17} style={{objectFit:"cover"}}  alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img18} style={{objectFit:"cover"}}  alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img19} style={{objectFit:"cover"}}  alt=" " />
                </div>
                {/* <div>
        <img src={ img20} alt=" " />
      </div> */}
                <div className="gallery-images">
                    <img src={img21} alt=" " />
                </div>
                <div className="gallery-images">
                    <img src={img22} alt=" " />
                </div>
            </div></>
    )
}

export default Gallery