const data = [
    {
        image: "/img/service/1.png",
        name: "Freshers' Welcome",
        desc: "Welcoming the newcomers to the IIT Kharagpur campus."
    },
    {
        image: "/img/service/2.png",
        name: "Cultural Night",
        desc: "A fun event full of cultural events like standup comedy, dance, singing."
    },
    {
        image: "/img/service/3.png",
        name: "Patang",
        desc: "The Kite Festival organised on the occasion of Makar Sankranti."
    },
    {
        image: "/img/service/4.png",
        name: "Khamma Ghanni",
        desc: "The Dal-Bati-Churma, Food festival organised on occasion of Holi."
    },
    {
        image: "/img/service/5.png",
        name: "Rammat",
        desc: "Sports event with some uncommon games like Kho-Kho, Kabbadi."
    },
    {
        image: "/img/service/6.png",
        name: "Farewell & Trip",
        desc: "Bidding goodbye to the passing out batch and farewell trip."
    },
]

export default data;