import React from 'react'
import { Link } from 'react-router-dom'

function Failure() {
  return (
    <>
    <div className="container" style={{marginTop:"14vh",marginBottom:"4rem"}}>
    <div className="row" style={{border:'4px solid #e9851a',maxWidth:'600px'}}>
       <div className="col-md-6 mx-auto">
          <div className="payment" style={{display:'flex',flexDirection:"column"}}>
             <div className="payment_header">
                <div className="check"><i className="fa fa-close" aria-hidden="true"></i></div>
             </div>
             <div className="content">
                <h1>Transaction Failed!</h1>
                <p  style={{color: 'black'}}>OOPS! Your payment has failed due to technical error. Please try once again by clicking the button below and if payment got deducted it will refunded back in your account within 7 Days.</p>
                <Link to="/register" relative="path" type="button" className="btn" style={{color:"red"}}>Try Again</Link>
             </div>
          </div>
       </div>
    </div>
 </div>
    </>
  )
}

export default Failure