import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Brad from '../Brad/Brad';
import { Link } from 'react-router-dom';


const Payment = () => {
  const [qrCode, setQrCode] = useState('');
  useEffect(() => {
    const qrCode = decodeURI(window.location.search).split("=")[1];
    setQrCode(qrCode);
  }, []);

  return (
    <>
      <Brad head="Khamma Ghanni" desc="The Food Fest" />
      <div className="container mt-2 mb-2">
        <div className="container-001 mt-5">
          <div className="header-001 ">
            <div className='circle-001 m-auto'>&#10004; </div>
          </div>
          <div className="content-001 mt-5">
            <p className='mb-4 text-center fs-3'>Payment Successfull!</p>
            <p className='fs-6 text-center p-3'>We have successfully reserved your passes. Please check your mail for your pass and important instructions for Khamma Ghani - The Foodfest 2023. Also, you can download the QR Code of your pass by clicking the download button below.</p>
          </div>
          <div className='text-center'>
            <Link to="/register" relative="path" type="button" className="khamma-button mb-4" style={{ color: "white", backgroundColor: "rgba(255, 102, 0, 1)" }}>Book More Passes</Link >
          </div>
        </div>
      </div>

      <div className="container">
        <div className="Payment" id="qrcodecont">
          <img src={qrCode} id="qrimage" alt="qrimage" style={{ display: 'block',margin:"auto" }} />
          <div className="content"><Link to={qrCode} className="khamma-button mt-3 mb-3" style={{ color: "white", backgroundColor: "rgba(255, 102, 0, 1)" }} download="rca_khamma_ghani_pass.png" id="qrdownloadimage">Download Unique QRCode</Link></div>
        </div>
      </div>

    </>
  )
}

export default Payment;