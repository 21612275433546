import React from 'react'
import data from './Sponsor'
import Card from './Card/Card'
import './Sponsor.css'
import Brad from '../Brad/Brad'
function Sponsor() {
    document.title = "Sponsors | RCA"
    return (
        <>
        <Brad head={"Our Sponsors"}></Brad>
            <div className="sponsor_container">


                {data.map((data, index) => (
                    <Card key={index} name={data.name} type={data.type} image={data.image} desc={data.desc} address={data.address} phone={data.phone} />
                ))}

            </div>
        </>
    )
}

export default Sponsor