import './App.css';
import Team from './Team/Team.jsx';
import Almuni from './Alumni/Alumni.jsx'
import Contact from './Contact/Contact.jsx'
import Navbar from './Navbar/Navbar.jsx'
import About from './About/about.jsx'
import Footer from './footer/footer'
import Event from './Event/Event.jsx'
import Register from './Registration/register.jsx'
import RegisterSuccess from './Registration/registerSuccess.jsx';
import Payment from './Registration/payment.jsx';
import Failure from './Registration/failure.jsx';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy.jsx';
import Terms from './PrivacyPolicy/Terms.jsx';
import ReturnPolicy from './PrivacyPolicy/ReturnPolicy.jsx';
import ShipingPolicy from './PrivacyPolicy/Shipping.jsx';
import Notfound from './Notfound/Notfound.jsx';
import FreshersRegistration from './FreshersRegistration/FreshersRegistration.jsx';
import MalhaarRegistrations from './MalhaarRegistrations/MalhaarRegistrations.jsx';
import {
  // BrowserRouter,
  Routes,
  Route,

} from "react-router-dom";
import Sponsor from './Sponsor/Sponsor.jsx';
import Homepage from './Hompage/Homepage';
import Scroll from './ScrollToTop/scrollToTop.jsx'
function App() {
  return (
    <>
      <Navbar />
       
      <Routes>

        <Route path="/" element={<Homepage />} />
        <Route path="/About" element={<About/>} />
        <Route path="/Event" element={<Event />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Sponsors" element={<Sponsor />} />
        <Route path="/Alumni" element={<Almuni />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path='/register' element={<Register/>}/>
        <Route path='/registrationsuccessful' element ={<RegisterSuccess/>}/>
        <Route path='/Payment' element= {<Payment/>}/>
        <Route path='/Failure' element= {<Failure/>}/>
        <Route path='/privacy' element={<PrivacyPolicy/>}/>
        <Route path='/MalhaarRegistrations' element={<MalhaarRegistrations/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/return' element={<ReturnPolicy/>}/>
        <Route path='/shipping' element={<ShipingPolicy/>}/>
        {/* <Route path='/FreshersRegistration' element={<FreshersRegistration/>}/> */}
        <Route path="*" element={<Notfound />} />

      </Routes>
      <Scroll></Scroll>
      <Footer />

    </>
  );
}

export default App;
