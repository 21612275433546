const data = [
   
    {
        name: "Mr. Paras meena",
        ImageUrl: "/Team/alum/paras.jpg",
        position: "Alumn 2023",
        facebookLink: "https://www.facebook.com/profile.php?id=100027701914776",
        linkedinLink: "https://www.linkedin.com/in/paras-meena-b52440199/"
    },
    {
        name: "Mr. Rahul kumawat",
        ImageUrl: "/Team/alum/rahul.jpg",
        position: "Alumn 2023",
        facebookLink: "https://www.facebook.com/rkeins.kumawat",
        linkedinLink: "https://www.linkedin.com/in/rahul-kumawat-922764194/"
    },
    {
        name: "Ms. Reena gurjar",
        ImageUrl: "/Team/alum/reena.jpg",
        position: "Alumn 2023",
        facebookLink: "###",
        linkedinLink: "https://www.linkedin.com/in/reena2910/"
    },
    {
        name: "Mr. Sunil choudhary",
        ImageUrl: "/Team/alum/sunil.jpg",
        position: "Alumn 2023",
        facebookLink: "https://www.facebook.com/profile.php?id=100008571826169",
        linkedinLink: "https://www.linkedin.com/in/sunil-choudhary-b36a02203/"
    },
    {
        name: "Mr. Sandeep ruhela",
        ImageUrl: "/Team/alum/sandeep.jpg",
        position: "Alumn 2023",
        facebookLink: "https://www.facebook.com/thesandeepruhela",
        linkedinLink: "https://www.linkedin.com/in/sandeepruhela08/"
    },
    {
        name: "Mr. Ankit sharma",
        ImageUrl: "/Team/alum/ankit.jpg",
        position: "Alumn 2023",
        facebookLink: "https://www.facebook.com/ankitsharma.aadrash",
        linkedinLink: "https://www.linkedin.com/in/ankit-sharma-140985202/"
    },
    {
        name: "Mr. Abhishek Sarda",
        ImageUrl: "/Team/alum/abhisheksharda.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/abhishek.maheshwari.iitkgp",
        linkedinLink: "https://www.linkedin.com/in/abhisheksardaiitkgp/"
    },
    {
        name: "Mr. Ankit Godara",
        ImageUrl: "/Team/alum/ankitg.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/profile.php?id=100018066872156",
        linkedinLink: "https://www.linkedin.com/in/ankit-godara-38b898176/"
    },
    {
        name: "Mr. Mahendra Urad",
        ImageUrl: "/Team/alum/mahendra.jpg",
        position: "Alumn 2022",
        facebookLink: "##",
        linkedinLink: "https://www.linkedin.com/search/results/all/?keywords=mahendra%20urad&origin=RICH_QUERY_SUGGESTION&position=1&searchId=33e6efa9-e4ab-4c1e-b41a-593b18fe3455&sid=_O-"
    },
    {
        name: "Mr. Narpat Kalwaniya",
        ImageUrl: "/Team/alum/narpat.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/ajoonp.kalwaniyan",
        linkedinLink: "https://www.linkedin.com/search/results/all/?keywords=narpat%20r%20kalwaniya&origin=RICH_QUERY_SUGGESTION&position=1&searchId=db083d6b-8d54-4b76-bcea-653ee6d903a6&sid=VxA"
    },
    {
        name: "Mr. Narendra Khichar",
        ImageUrl: "/Team/alum/narendra.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/narendra.khichar.543",
        linkedinLink: "https://www.linkedin.com/search/results/all/?keywords=narendra%20khichar&origin=RICH_QUERY_SUGGESTION&position=5&searchId=289e9f77-da7a-4e84-bab7-8836469f7f4c&sid=u0%2C"
    },
    {
        name: "Mr. Prince Meena",
        ImageUrl: "/Team/alum/prince.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/prince.iitkgp.99",
        linkedinLink: "https://www.linkedin.com/search/results/all/?keywords=prince%20meena&origin=RICH_QUERY_SUGGESTION&position=0&searchId=df94373f-9a6b-47df-9db5-2832dae59801&sid=6_X"
    },
    {
        name: "Mr. Prdeep Ranwa",
        ImageUrl: "/Team/alum/pradeep.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/pradeep.jat.14855",
        linkedinLink: "https://www.linkedin.com/search/results/all/?keywords=pradeep%20ranwa&origin=RICH_QUERY_SUGGESTION&position=1&searchId=0abe6398-16fe-408a-9681-0f57ded9440d&sid=)1j"
    },
    {
        name: "Mr. Sumit Mahala",
        ImageUrl: "/Team/alum/sumit.jpg",
        position: "Alumn 2022",
        facebookLink: "##",
        linkedinLink: "##"
    },
    {
        name: "Mr. Vishnu Dutt Soni",
        ImageUrl: "/Team/alum/vishnu.jpg",
        position: "Alumn 2022",
        facebookLink: "##",
        linkedinLink: "##"
    },
    {
        name: "Mr. Pradeep Kumar",
        ImageUrl: "/Team/alum/pradeepk.jpg",
        position: "Alumn 2022",
        facebookLink: "https://www.facebook.com/profile.php?id=100015910306329",
        linkedinLink: "https://www.linkedin.com/in/pradeep-kumar-bijarniya/"
    },
    {
        name: "Mr. Shishir Goyal",
        ImageUrl: "/Team/alum/shishir.jpg",
        position: "Alumn 2021",
        facebookLink: "##",
        linkedinLink: "https://www.linkedin.com/in/shishir-goyal-1949b4112?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3Bj2%2F5RJonRkOixSkuVNV2Qw%3D%3D"
    },
    {
        name: "Mr. Abhishek Meena",
        ImageUrl: "/Team/alum/abhishek.jpg",
        position: "Alumn 2021",
        facebookLink: "https://www.facebook.com/abhishek.meena.31924792",
        linkedinLink: "https://www.linkedin.com/in/abhishekmeena3888?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BI6IRbGxBTtutwcqEo3sR%2Fw%3D%3D"
    },
    {
        name: "Mr. Arun Choudhary",
        ImageUrl: "/Team/alum/arun.jpg",
        position: "Alumn 2021",
        facebookLink: "https://www.facebook.com/profile.php?id=100005281177576",
        linkedinLink: "##"
    },
    {
        name: "Mr. Bajrang Kaswan",
        ImageUrl: "/Team/alum/bajarang.jpg",
        position: "Alumn 2021",
        facebookLink: "##",
        linkedinLink: "https://www.linkedin.com/in/bajrang-kaswan-bb9ab718b?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BhVvr9cpkRs2MYsEcs%2BNS6A%3D%3D"
    },
    {
        name: "Mr. Vikas Raghav",
        ImageUrl: "/Team/alum/vikas.jpg",
        position: "Alumn 2021",
        facebookLink: "https://www.facebook.com/vikas.ragav",
        linkedinLink: "https://www.linkedin.com/in/vikas-raghav-589287167?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B%2BwsAkSJZQgewFZQ66dYxsA%3D%3D"
    },
    {
        name: "Ms. Monica Marmit",
        ImageUrl: "/Team/alum/monica.jpg",
        position: "Alumn 2021",
        facebookLink: "###",
        linkedinLink: "https://www.linkedin.com/in/monica-marmit-iitkgp?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BRrDGGUGdTgyugg1FrnxSRw%3D%3D"
    },
    {
        name: "Ms. Nisha Meena",
        ImageUrl: "/Team/alum/nisha.jpg",
        position: "Alumn 2021",
        facebookLink: "##",
        linkedinLink: "https://www.linkedin.com/in/nisha-meena02?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BazL5ROx4Q%2Fa9kbqbyJgJRQ%3D%3D"
    },
    {
        name: "Mr. Jakendra Somarwal",
        ImageUrl: "/Team/alum/jakendra.jpg",
        position: "Alumn 2021",
        facebookLink: "https://www.facebook.com/jakendra.somarwal",
        linkedinLink: "https://www.linkedin.com/in/jakendra-somarwal-02ba66169?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BpSwOT05nTRy%2BKrZqcXnCuQ%3D%3D"
    },
    {
        name: "Mr. Goverdhan Singh",
        ImageUrl: "/Team/alum/goverdhan.jpg",
        position: "Alumn 2021",
        facebookLink: "https://www.facebook.com/goverdhan.singh.31508",
        linkedinLink: "https://www.linkedin.com/in/goverdhan-singh-73033115a?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BtKztydRfRp2Gi46mKB4DNg%3D%3D"
    },
   {
        name: "Mr. Surendra Choudhary",
        ImageUrl: "/Team/alum/surendra.jpg",
        position: "Alumn 2020",
        facebookLink: "https://www.facebook.com/surendrachoudhary628",
        linkedinLink: "https://www.linkedin.com/in/sl628?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BiWoqoeWITvC8mlvenUu8TA%3D%3D"
    },
    {
        name: "Mr. Shakti Singh",
        ImageUrl: "/Team/alum/shakti.jpg",
        position: "Alumn 2017",
        facebookLink: "https://m.facebook.com/shakti.singhmeena.75?ref=bookmarks",
        linkedinLink: "https://www.linkedin.com/in/shakti-meena-56322693"
    },
   
  
]

export default data
