import React from 'react'

function ReturnPolicy() {
  return (
    <>
      <div className="mx-auto border rounded p-5 shadow privacydiv" style={{marginTop:"10rem","marginBottom":"2rem",width:"80%",}}>
        <h2><strong>Return or Refund Policy</strong></h2>
        <p>Last updated: March 03, 2024</p>
        <p>
        All sales are final and no return or refund will be issued.
        </p>
        <p>
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <h3>
          QUESTIONS
        </h3>
        <p>
          If you have any questions concerning our return policy, please contact us at:
        </p>
        <ul>
          <li>+91-7877891170</li>
          <li>rca.iitkgp@gmail.com</li>
        </ul>
      </div>
    </>
  )
}

export default ReturnPolicy