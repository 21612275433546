import React from 'react'

function ShipingPolicy() {
  return (
    <>
      <div className="mx-auto border rounded p-5 shadow privacydiv" style={{marginTop:"10rem","marginBottom":"2rem",width:"80%",}}>
        <h2><strong>Shipping or Delivery Policy:</strong></h2>
        <p>Last updated: March 03, 2024</p>
        <p>
        At Rajasthan Cultural Association, we prioritize your convenience and security throughout the shipping process. Here's how we ensure a smooth delivery experience:
        </p>
        <h4>
          Email Delivery of QR Codes:
        </h4>
        <p>
          <ul>
            <li>For your ease of access, we will send QR codes directly to your provided email address.</li>
            <li>These QR codes serve as your coupons, providing a hassle-free and efficient method of redemption.</li>
          </ul>
        </p>
        <p>
          If you have any questions concerning our Shipping and Delivery policy, please contact us at:
        </p>
        <ul>
          <li>+91-7877891170</li>
          <li>rca.iitkgp@gmail.com</li>
        </ul>
      </div>
    </>
  )
}

export default ShipingPolicy