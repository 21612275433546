const data = [
   
    {
        name: "Rakesh Rulaniya",
        ImageUrl: "/Team/Seniors/Rakesh.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/profile.php?id=100074362301892",
        linkedinLink: "https://www.linkedin.com/in/rakesh-rulaniya-958a2222b"
    }, 
    {
        name: "Devansh Jain",
        ImageUrl: "/Team/Seniors/Devansh.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/profile.php?id=100073358217898",
        linkedinLink: "https://www.linkedin.com/in/devansh-jain11"
    },
    {
        name: "Ankit Kulariya",
        ImageUrl: "/Team/Seniors/anku.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/ankit.kulariya.33",
        linkedinLink: "https://www.linkedin.com/in/ankit-kulariya-92997822b"
    },
    {
        name: "Aditya Singh ",
        ImageUrl: "/Team/Seniors/Aditya_.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/profile.php?id=100074852065726",
        linkedinLink: "https://www.linkedin.com/in/aditya-singh-shekhawat-69519a226/"
    },
    {
        name: "Kapil Yadav",
        ImageUrl: "/Team/Seniors/Kapil.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/profile.php?id=100050601349695&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/kapil-kumar-856246233"
    },
    {
        name: "Subhash Kumar Ola",
        ImageUrl: "/Team/Seniors/Subh.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/profile.php?id=100016985798663&mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/subhash-kumar-ola"
    },
    {
        name: "Vivek Gurjar",
        ImageUrl: "/Team/Seniors/vivek.jpg",
        position: "Steering Committee Member",
        facebookLink: "https://www.facebook.com/vivek.gurjar.5473?mibextid=ZbWKwL",
        linkedinLink: "https://www.linkedin.com/in/vivek-gurjar-665a01250"
    },
  
]

export default data