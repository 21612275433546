const data = [
    {
        image: "img/sponsors/computer_city.png",
        name : "Computer City",
        type: "Gold Sponsor",
        desc: "A computer and mobile shop with all kind of new and repair parts.",
        address: "Address:- Shop no 5, PURIGATE, IIT Main Rd, Kharagpur, West Bengal 721306.",
        phone: "+91-9832278786",
    },
    // {
    //     image: "img/sponsors/alice_opticals.png",
    //     name : "Alice Opticals",
    //     type: "Gold Sponsor",
    //     desc: "An optical shop is a retail establishment that specializes in the sale of eyeglasses, contact lenses, and other optical products.",
    //     address: "Address:- 181 Gole Bazar, Kharagpur - 721301 (Behind Railway Cloth Market)",
    //     phone: "+91-9800004570",
    // },
    // {
    //     image: "img/sponsors/shyam_cycle_store.png",
    //     name : "Shyam Cycle Store",
    //     type: "Gold Sponsor",
    //     desc: "A cycle store is a retail establishment that specializes in selling bicycles and cycling accessories.",
    //     address: "Address:- Kaushallya More, Kaushallya, Kharagpur, West Bengal 721301.",
    //     phone: "+91-9434987320",
    // },
    // {
    //     image: "img/sponsors/kharagpur_cloth_center.png",
    //     name : "Raymond Kharagpur Cloth Center",
    //     type: "Gold Sponsor",
    //     desc: "A cloth center of Raymond is a retail establishment that specializes in selling clothing items from Raymond.",
    //     address: "Address:- Shop No NTE 209, Gole Bazar, Kharagpur, West Bengal 721301.",
    //     phone: "+91-9333741778",
    // },
    // {
    //     image: "img/sponsors/chai_sutta.png",
    //     name : "Chai Sutta Bar",
    //     type: "Silver Sponsor",
    //     desc: "Chai Sutta Bar envisions to make chai more the go-to drink for all Indians.",
    //     address: "Address:- Opposite to Spencer Mall , Kaushallya More, Kharagpur, India, West Bengal.",
    //     phone: "+91-8617087797",
    // },

]

export default data